<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      :title="row.name ? '编辑配置' : '新增配置'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item label="标题">
          <a-input v-decorator="formDecorators.title" />
        </a-form-item>

        <a-form-item label="简介">
          <a-input v-decorator="formDecorators.description" />
        </a-form-item>

        <a-form-item label="标识">
          <a-input v-decorator="formDecorators.name" />
        </a-form-item>
        <a-form-item label="颜色">
          <input type="color" v-decorator="formDecorators.color">
          <!-- <a-input v-decorator="formDecorators.color" /> -->
        </a-form-item>
      </a-form>

      <div class="list-title list-box">
        <div class="key">key</div>
        <div class="value">值</div>
        <div class="name">名字</div>
        <div class="action">操作</div>
      </div>

      <div class="list-box"  v-for="(item,index) in list"  :key="index"  >
        <div class="key ipt-box" v-if="item.isEdit">
          <a-input v-model="item.key" ></a-input>
        </div>
        <div class="key" v-else >{{item.key}}</div>

        <div class="value ipt-box" v-if="item.isEdit">
          <a-input v-model="item.value" ></a-input>
        </div>
        <div class="value value-txt" v-else >{{item.value}}</div>

       <div class="name ipt-box" v-if="item.isEdit">
          <a-input v-model="item.name" ></a-input>
        </div>
        <div class="name" v-else >{{item.name}}</div>
        <div class="action">
          <a
            @click="setEdit(index,false)"
            v-if="item.isEdit"
            >保存</a>
            <a
              @click="setEdit(index,true)"
              v-else
              >编辑</a>
          <a-divider type="vertical" />
          <a
            @click="list.splice(index,1)"
            >删除</a
          >
        </div>
      </div>

      <a-button
        type="dashed"
        style="width: 100%; margin-top: 10px"
        @click="addItem"
      >
        <a-icon type="plus" /> 新增
      </a-button>
    </a-modal>
  </div>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
import tool from "@/utils/tool";
export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      SHOW_PARENT,
      list: [],
      item:{
        key:'key',
        name:'name',
        value:'value',
        isEdit:true
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        title: [
          "title",
          {
            initialValue: data && data.title,
            rules: [{ required: true, message: "请填写标题" }],
            validateTrigger: ["blur"],
          },
        ],
        description: [
          "description",
          {
            initialValue: data && data.description,
          },
        ],
        name: [
          "name",
          {
            initialValue: data && data.name,
            rules: [{ required: true, message: "请填写标识" }],
          },
        ],
        color: [
          "color",
          {
            initialValue: data && data.color,
            rules: [{ required: true, message: "请设置背景颜色" }],
          },
        ],
      };
    },
  },
  created() {
    if(this.row.value){
      this.list=this.row.value.map(item =>{
        item.isEdit=false;
        return item
      })
    }
  },
  mounted() {

  },
  filters: {},
  methods: {
    // 设置编辑
    setEdit(index,bl){
      this.list[index].isEdit=bl;
      this.$forceUpdate()
    },
    addItem() {
      this.list.push(JSON.parse(JSON.stringify( this.item)))
    },
    handleOk() {
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.value=this.list
          this.$api
            .post(`/v2/systemConfiguration/addOrUpd`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("操作成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        } else {
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.$emit("handleCancel");
    }
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list-title {
  background: #eee;
}

.list-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 14px;
  color: #333;
  border-bottom: solid 1px #eee;
  .key {
    width: 25%;
    text-align: center;
  }
  .value {
    width: 25%;
    text-align: center;
  }

  .value-txt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .name {
    width: 30%;
    text-align: center;
  }
  .action {
    width: 20%;
    text-align: center;
  }

  .ipt-box{
    margin: 0 5px;
  }


}
</style>
