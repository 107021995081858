<template>
    <div class="dashboard-container">
      <div class="card-box"  v-for="(item,index) in list" :key="index"     >
        <div class="title-box">
          <div class="color"  :style="{background: item.color}"  ></div>
          <div class="title">{{item.title}}</div>
        </div>
        <div class="card-alias">
          标识：{{item.name}}
        </div>

        <div class="btn-box">
        <a-popconfirm
          title="确定删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delItem(item.name)"
        >
          <le-button width="40px" height="22px" fontSize="12px" class="btn btn-primary" txt="删除" background="linear-gradient(-10deg, #FD8A8F, #FA6A9D)"  />
        </a-popconfirm>
          <le-button width="40px" height="22px" fontSize="12px" class="btn btn-primary" txt="编辑"  style="margin-left: 10px;"  @btnClick="rows=item;visible=true"   />
        </div>
      </div>
      <div class="add-box" @click="rows={};visible=true">
        <a-icon type="plus" style="font-size: 36px;color: #ddd;" />
      </div>
      <pop-edit 
      v-if="visible"
      :visible="visible"
      :row="rows" 
      @handleCancel="visible=false"
      @handleOk="visible=false;getData()"
      />
    </div>
</template>

<script>
import popEdit from "./popEdit.vue"
import PopEdit from "./popEdit.vue"
export default {
  name: "authConfig",
  components: {
    popEdit,
    PopEdit
},
  data() {
    return {
      list:[],
      rows:{},
      visible:false
    };
  },
  computed: {
  },
  activated() {
    this.getData();
  },
  mounted(){
    this.getData()
  },
  methods: {
    // 删除配置
    delItem(name){
      this.$api.post('v2/systemConfiguration/del',{name}).then((res) => {
        console.log(res)
        if(res.code==200){
          this.getData()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getData(){
      this.$api.post('v2/systemConfiguration/get').then((res) => {
        console.log(res)
        if(res.code==200){
          this.list=res.data
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card-box{
    width: 280px;
    height: 130px;
    border-radius: 15px;
    background-color: #fff;
    background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/qiE/config/config-bg.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin:0 5px;
    margin-bottom: 10px;
    .title-box{
      height:42px;
      margin: 0 11px;
      border-bottom: solid 1px #eee;
      display: flex;
      align-items: center;
      .color{
        width: 12px;
        height: 12px;
        border-radius: 3px;
        margin-top: 2px;
      }
      .title{
        color: #222;
        font-size: 16px;
        font-weight: bold;
        margin-left: 5px;
      }
    }
    .card-alias{
      color: rgba(34,34,34,0.6);
      font-size: 14px;
      margin-top: 10px;
      margin-left: 10px;
    }
    .btn-box{
      display: flex;
      justify-content: end;
      margin-top: 25px;
      margin-right: 10px;
    }
  }

  .card{
    width: 180px;
    height: 120px;
    margin:0 5px;
    margin-bottom: 10px;
    cursor: pointer;
    .title{
      margin-left: 20px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
    }
    .des{
      font-size: 12px;
      margin-left: 10px;
      width: 160px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

    }
    .del-box{
      width: 20px;
      height: 20px;
      background: #ff0000;
      top: 0;
      right: 0;
      position: absolute;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
    }
  }
  .add-box{
    width: 280px;
    height: 130px;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin:0 5px;
    margin-bottom: 10px;
  }
}
</style>
